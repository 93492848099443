import { useDispatch, useSelector } from "react-redux";
import Table from "../ui/table/TableController";
import {
  updateItemizer,
  updateItemizerState,
  companiesSelector,
} from "../../redux/slices/companiesSlice";
import { uiSelector } from "../../redux/slices/uiSlice";
import Loading from "./tableState/Loading";
import NoSearch from "./tableState/NoSearch";
import NoItems from "./tableState/NoItems";

const Itemizer = () => {
  const { itemizers, tableLoading } = useSelector(companiesSelector);
  const dispatch = useDispatch();
  const { searchQuery } = useSelector(uiSelector);

  const filteredItemizers = itemizers.filter(
    (i) =>
      String(i.id).includes(searchQuery) ||
      i.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const headerData = [
    { id: 122, label: "Company", width: "25%", sortable: false },
    { id: 222, label: "Id", width: "25%", sortable: true },
    { id: 322, label: "Name", width: "25%", sortable: true },
    { id: 422, label: "Tax Inclusive", width: "10%", sortable: true },
    { id: 522, label: "Action", width: "25%", sortable: false },
  ];

  const rowKeys = ["company_id", "id", "name", "tax"];

  const editable = ["name"];

  const sortByData = [
    {},
    {
      label: "floor_id",
      type: "number",
    },
    {
      label: "floor_name",
      type: "string",
    },
    {},
  ];

  const updateAction = (x) => {
    dispatch(updateItemizerState(x));
    return updateItemizer(x);
  };

  if (tableLoading) return <Loading />;

  if (filteredItemizers.length === 0 && searchQuery) return <NoSearch />;

  if (filteredItemizers.length === 0) return <NoItems />;

  return (
    <Table
      sortByData={sortByData}
      headerData={headerData}
      rowKeys={rowKeys}
      data={filteredItemizers}
      editable={editable}
      row_key="id"
      updateAction={updateAction}
    />
  );
};

export default Itemizer;
