import { useState } from "react";
import { useDispatch } from "react-redux";
import lodash from "lodash";

import { Button } from "@lightspeed/flame/Button";
import { IconEdit } from "@lightspeed/flame/Icon/Edit";
import styled from "@emotion/styled";

import Select from "react-select";

import { setAlert } from "../../../redux/slices/uiSlice";

// icons

import { useSelector } from "react-redux";
import { uiSelector } from "../../../redux/slices/uiSlice";

const TdStyled = styled.td`
  padding: 0 8px !important;
  height: 0 !important;
`;

const Row = ({ item, editable, updateAction, rowKeys, editableOptions }) => {
  const [edit, editSet] = useState(false);
  const [value, valueSet] = useState({});
  const dispatch = useDispatch();
  const { theme } = useSelector(uiSelector);

  const customStyle = {
    option: (base, state) => ({
      ...base,
      backgroundColor: theme === "flame" ? "#fff" : "#333",
      color: theme === "flame" ? "#333" : "#fff",
    }),
    menu: (base, state) => ({
      ...base,
      backgroundColor: theme === "flame" ? "#fff" : "#333",
      color: theme === "flame" ? "#333" : "#fff",
    }),
    control: (base, state) => ({
      ...base,
      backgroundColor: theme === "flame" ? "#fff" : "#333",
      color: theme === "flame" ? "#333" : "#fff",
    }),
    singleValue: (base, state) => ({
      ...base,
      backgroundColor: theme === "flame" ? "#fff" : "#333",
      color: theme === "flame" ? "#333" : "#fff",
    }),
  };

  const onUpdate = () => {
    if (lodash.isEmpty(value)) return editSet(false);

    let newItem = { ...item };

    if (value.category_type) {
      newItem.category_type = value.category_type.label;
    }

    if (value.pms_name) {
      newItem.pms_name = value.pms_name.label;
      newItem.pms_id = value.pms_name.value;
    }

    if (value.pms_name1) {
      newItem.pms_name1= value.pms_name1.label;
      newItem.pms_id1 = value.pms_name1.value;
    }
    if (value.pms_name2) {
      newItem.pms_name2 = value.pms_name2.label;
      newItem.pms_id2 = value.pms_name2.value;
    }

    editSet(false);
    dispatch(updateAction(newItem)).then((res) => {
      dispatch(
        setAlert({
          type: "success",
          title: "Success",
          text: "Task completed successfuly",
        })
      );
      setTimeout(() => {
        dispatch(
          setAlert({
            type: "",
            title: "",
            text: "",
          })
        );
      }, 3000);
    });

    // show fail and reset
  };

  const onEdit = () => editSet(true);

  return (
    <tr
      onDoubleClick={onEdit}
      className={`${theme === "flame" ? "" : "dark-table-row"}`}
    >
      {rowKeys.map((i) => {
        if (editable.includes(i) && edit) {
          return (
            <TdStyled key={Math.random()}>
              <Select
                styles={customStyle}
                defaultValue={{
                  label: item[i],
                  value: item[i],
                }}
                value={value[i]}
                onChange={(e) =>
                  valueSet((old) => {
                    if (item[i] === e.label) {
                      const tmpOld = { ...old };
                      delete tmpOld[i];
                      return tmpOld;
                    }
                    return { ...old, [i]: e };
                  })
                }
                options={editableOptions[i]}
              />
            </TdStyled>
          );
        }
        if (value[i]) {
          return <TdStyled key={Math.random()}>{value[i].label}</TdStyled>;
        }
        return <TdStyled key={Math.random()}>{item[i]}</TdStyled>;
      })}

      {edit ? (
        <TdStyled>
          <Button onClick={onUpdate}>Save</Button>
          <Button
            onClick={() => {
              valueSet({});
              editSet(false);
            }}
          >
            Cancel
          </Button>
        </TdStyled>
      ) : (
        <TdStyled>
          <Button onClick={onEdit}>
            <IconEdit baseColor={theme === "flame" ? "#333" : "#fff"} />
          </Button>
        </TdStyled>
      )}
    </tr>
  );
};

export default Row;
